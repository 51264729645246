import useJwt from '@/auth/jwt/useJwt';

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    fetchPharmarcies(ctx, data) {
      return useJwt.getPharmacies(data).then(response => response);
    },

    createPharmacy(ctx, data) {
      return useJwt.createPharmacy(data).then(response => response);
    },

    updatePharmacy(ctx, data) {
      return useJwt.updatePharmacy(data).then(response => response);
    },

    deletePharmacy(ctx, id) {
      return useJwt.deletePharmacy(id).then(response => response);
    },
  },
};
